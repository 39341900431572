<template>
  <div class="game-list-container">
    <div class="header">
      <div class="search-input">
        <el-popover placement="bottom-start" width="300" v-model="visible" :disabled="disabled" :visible-arrow="false"
          :append-to-body="false" :popper-options="{
            boundariesElement: 'body',
            gpuAcceleration: true,
            positionFixed: true,
            preventOverflow: true,
          }">
          <ul class="infinite-list">
            <li v-for="(v, i) in searchList" :key="i" class="infinite-list-item ellipsis" @click="selectGame(v)">
              <div>{{ v.GameName }}</div>
              <div>{{ v.GameAliasName }}</div>
            </li>
          </ul>
          <el-input v-model="keyword" placeholder="请输入游戏名" class="input-with-select" @input="inputChange"
            @keyup.enter.native="search" @clear="clear" slot="reference" clearable>
            <el-button slot="append" icon="el-icon-search" @click="search"></el-button>
          </el-input>
        </el-popover>
      </div>
      <div class="radio-group">
        <div class="radio-item" :class="{ active: radio.indexOf(1) > -1 }" @click="radioChange(1)">
          <span class="radio-input"></span>提供游戏账号
        </div>
        <div class="radio-item" :class="{ active: radio.indexOf(2) > -1 }" @click="radioChange(2)">
          <span class="radio-input"></span>支持中文
        </div>
        <div class="radio-item" :class="{ active: radio.indexOf(3) > -1 }" @click="radioChange(3)">
          <span class="radio-input"></span>支持完美存档
        </div>
        <div class="radio-item">
          此电脑已安装
          <span style="font-size: 20px; color: rgb(255, 54, 54)">{{
            total
          }}</span>
          款游戏
        </div>
      </div>
    </div>
    <div class="list-box">
      <ul v-if="list.length">
        <li v-for="item in list" :key="item.ProductID || item.GameID || item.SteamGameId">
          <img :src="item.GameImgUrl2" alt="" />
          <div class="game_name ellipsis">{{ item.GameName }}</div>
          <div class="name_en ellipsis">{{ item.GameAliasName }}</div>
          <div class="tags-box">
            <span class="tags" v-show="item.Count">提供完美存档</span>
            <span class="tags" v-show="item.GameID">提供账号</span>
            <span class="tags" v-show="item.GameLanguage.indexOf('中文') > -1">支持中文</span>
          </div>
          <div class="mask-box">
            <div class="btn-box">
              <div class="btn" @click="startGame(item)">
                <span v-if="loading"><i class="el-icon-loading"></i>启动中，请稍等...</span>
                <span v-else>启动steam客户端</span>
              </div>

              <div class="btn" @click="toDetail(item)" v-if="item.ProductID">
                <span v-if="item.loading1"><i class="el-icon-loading"></i>启动中，请稍等...</span>
                <span v-else>免费玩游戏</span>
              </div>
            </div>
          </div>
        </li>
      </ul>
      <div v-if="!list.length" class="no-data">
        <i class="el-icon-document"></i>
        <div class="text">
          没有找到您想要的游戏<span @click="startGame()" style="color: #ff3636; cursor: pointer; margin: 0 5px">启动steam</span>下载游戏
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { GetGames } from "@/api";
import cMethods from "@/util/cMethods.js";
import steamImg from "@/assets/img/steam2.png";

export default {
  name: "gameMenu",
  computed: {
    ...mapGetters(["userInfo", "isLogin", "netBarId"]),
  },
  data() {
    return {
      searchList: [],
      allList: [],
      list: [],
      total: 0,
      keyword: "",
      radio: [],
      loading: false,
      visible: false,
      disabled: true,
      currentItem: {},
    };
  },
  created() {
    window.gameMenuApp = this;
    this.getLocalGames();
    this.$statistic(["A", "游戏菜单", "页面", "展现量"]);
  },
  mounted() {
    this.machineCode = cMethods.FormGetMachineCode();
  },
  methods: {
    toDetail(item) {
      if (item.ProductID) {
        this.$router.push(`/gameDetail/${item.ProductID}`);
      }
    },
    startGame(item) {
      if (item) {
        if (this.loading) return;
        this.loading = true;
        let result = "";
        if (item.SteamGameId) {
          result = cMethods.OpenOrgSteamLogin(item.SteamGameId);
        } else {
          result = cMethods.OpenOrgSteamLogin("");
        }
        if (result === "success") {
          setTimeout(() => {
            this.loading = false;
          }, 5000);
        } else {
          this.loading = false;
          this.$message.error(result || "启动失败");
        }
      } else {
        let result = cMethods.OpenOrgSteamLogin();
        if (result !== "success") {
          this.$message.error(result || "启动失败");
        }
      }
      this.$statistic(["B", "游戏菜单", "启动steam客户端", "按钮点击量"]);
    },
    getLocalGames() {
      let res = cMethods.Form_jsGetLocalSsteamInstalledGames();
      console.log("返回网吧id==>>>", res)
      // let res = "105600,108600,1091500,1139900,1142710,1144200,1147560,1151340,1158310,1174180,1196590,1245620,1248130,1259420,1361210,1404210,1446780,1543030,1593500,1621690,1687950,1794680,1938090,221100,227300,228980,236850,250900,261550,265550,270880,271590,278080,281990,289070,292030,307690,367520,368070,377160,381210,394360,397540,4000,412020,413150,414700,427520,433340,457140,518790,524220,526870,529340,548430,552520,578080,588650,601150,646570,648800,813780,892970,927380,960090,";
      //|| '292030,271590,367520,518790,1145360,377160,1091500,1313140,1097150,578080,'
      if (res) {
        this.getList(res.substr(0, res.length - 1));
      }
    },
    async getList(steamGameId) {
      let loading = this.$loading({
        lock: true,
        // fullscreen: false,
        text: `数据加载中...`,
        // body: false,
        // target: '.game-list-container',//document.querySelector('.game-list-container'),
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
        customClass: "loading-box",
      });
      let params = {
        netbarId: this.netBarId,
        steamGameId: steamGameId,
        gameNmae: "",
        gameAliasName: "",
        gameLanguage: "",
        pageIndex: 1,
        pageSize: 10000,
      };
      let res = await GetGames(params);

      loading.close();
      if (res.StatusCode === 200) {
        // this.$store.commit("SET_LOCALGAMESNUM", res.Items.length);
        res.Items.forEach((v) => {
          v.loading1 = false;
          v.loading2 = false;
        });
        res.Items.unshift({
          GameName: "steam平台",
          Name: "",
          GameAliasName: "",
          GameImgUrl2: steamImg,
          SteamGameId: 0,
          GameLanguage: "",
          GameID: "",
          Count: 0,
          GameLabel: "",
        });
        let arr = [];
        res.Items.forEach((item) => {
          if (item.SteamGameId === "292030") {
            if (item.Name === "巫师3年度版") {
              arr.push({ ...item, GameName: item.Name });
            }
          } else if (item.SteamGameId === "582010") {
            if (item.Name === "怪物猎人：世界") {
              arr.push({ ...item, GameName: item.Name });
            }
          } else if (item.SteamGameId === "578080") {
            arr.push({ ...item, GameName: "绝地求生" });
          } else {
            arr.push(item);
          }
        });
        this.list = arr;
        this.allList = arr;

        this.total = this.list.length;
      }
    },

    radioChange(e) {
      this.searchList = [];
      this.keyword = "";
      this.visible = false;
      this.disabled = true;
      if (this.radio.indexOf(e) > -1) {
        this.radio.splice(this.radio.indexOf(e), 1);
      } else {
        this.radio.push(e);
      }
      if (this.radio.length) {
        let list = this.allList;
        this.radio.forEach((v) => {
          if (v === 1) {
            list = list.filter((v) => v.GameID);
          } else if (v === 2) {
            list = list.filter((v) => v.GameLanguage.indexOf("中文") > -1);
          } else if (v === 3) {
            list = list.filter((v) => v.Count);
          }
        });
        this.list = list;
      } else {
        this.list = this.allList;
      }
    },
    inputChange(e) {
      console.log(e);
      if (e) {
        // this.searchList = this.list.filter(v => ((v.GameName.indexOf(e) > -1 || e.indexOf(v.GameName) > -1)|| (v.Name.indexOf(e) > -1 || e.indexOf(v.Name) > -1) || (v.GameAliasName.indexOf(e) > -1 || e.indexOf(v.GameAliasName) > -1)))
        this.searchList = this.allList.filter(
          (v) =>
            v.GameName.indexOf(e) > -1 ||
            v.Name.indexOf(e) > -1 ||
            v.GameAliasName.indexOf(e) > -1
        );
      } else {
        this.searchList = [];
      }
      console.log(this.searchList);
      this.visible = !!this.searchList.length;
      this.disabled = !this.searchList.length;
    },
    selectGame(item) {
      this.keyword = item.GameName;
      this.visible = false;
      this.disabled = false;
      this.radio = [];
      this.search();
    },
    clear() {
      this.list = this.allList;
    },
    search() {
      this.visible = false;
      this.disabled = true;
      this.radio = [];
      this.list = this.allList.filter(
        (v) =>
          v.GameName.indexOf(this.keyword) > -1 ||
          v.Name.indexOf(this.keyword) > -1 ||
          v.GameAliasName.indexOf(this.keyword) > -1
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.game-list-container {
  padding: 20px;

  .header {
    display: flex;
    // justify-content: center;
    align-items: center;
    margin-bottom: 16px;

    ::v-deep .el-radio-group {
      margin-left: 60px;

      .el-radio {
        color: #75838f;

        .el-radio__inner {
          border-color: #8f98a0;
          background: transparent;

          &::after {
            width: 8px;
            height: 8px;
            background-color: #8f98a0;
          }
        }

        .is-checked {
          .el-radio__inner {
            border-color: $red-col;

            &::after {
              background-color: $red-col;
            }
          }

          &+.el-radio__label {
            color: $red-col;
          }
        }
      }
    }

    .search-input {
      width: 376px;
      height: 40px;
      position: relative;

      ::v-deep .el-input {

        // border: 1px solid #39AE55;
        // border-radius: 2px;
        .el-input__inner {
          background-color: transparent !important;
          border: 1px solid $red-col !important;
        }

        .el-input-group__append {
          background-color: $red-col;
          border: 1px solid $red-col;
          color: #fff;
        }
      }

      ::v-deep .el-popover {
        left: 0;
        top: 46px;
        // background: #10171f;
        // border-color: #10171f;
        @include item_bg_col();

        &::after {
          content: "";
          position: absolute;
          border: 8px solid transparent;
          border-bottom-color: $red_col();
          top: -17px;
          left: 50%;
          margin-left: -8px;
        }
      }
    }

    .radio-group {
      display: flex;
      margin-left: 60px;

      .radio-item {
        color: #75838f;
        font-size: 14px;
        display: flex;
        user-select: none;
        align-items: center;
        margin-right: 30px;
        cursor: pointer;

        .radio-input {
          border: 1px solid #8f98a0;
          border-radius: 100%;
          width: 14px;
          height: 14px;
          cursor: pointer;
          box-sizing: border-box;
          margin-right: 10px;
        }

        &.active {
          color: $red-col;

          .radio-input {
            border-color: $red-col;
            position: relative;

            &::after {
              content: "";
              width: 8px;
              height: 8px;
              background-color: $red-col;
              position: absolute;
              left: 50%;
              top: 50%;
              border-radius: 100%;
              transform: translate(-50%, -50%);
            }
          }
        }
      }
    }
  }

  .list-box {
    height: 624px;
    overflow-y: scroll;
    border-radius: 8px;

    &::-webkit-scrollbar {
      width: 4px;
      height: 1px;
      margin-right: 10px;
    }

    ul {
      // padding: 16px;
      display: flex;
      flex-wrap: wrap;
      border-radius: 8px;

      li {
        width: 242px;
        height: 180px;
        @include item_bg_col();
        @include font_col9();
        font-size: 12px;
        text-align: center;
        margin-top: 20px;
        margin-left: 20px;
        position: relative;
        border-radius: 5px;
        box-sizing: border-box;

        &:nth-child(4n + 1) {
          margin-left: 0;
        }

        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4) {
          margin-top: 0;
        }

        img {
          width: 100%;
          height: 108px;
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;
        }

        .game_name {
          @include font_col3();
          font-size: 16px;
          margin: 5px 0;
        }

        .name_en {
          margin-bottom: 5px;
        }

        .tags-box {
          display: flex;
          align-items: center;
          justify-content: center;

          .tags {
            padding: 0 5px;
            line-height: 20px;
            border: 1px solid #656d74;
            border-radius: 3px;
            margin: 0 3px;
          }
        }

        .mask-box {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          background: rgba(0, 0, 0, 0.5);
          border: 2px solid $red-col;
          border-radius: 5px;
          display: none;
          z-index: 99;
          box-sizing: border-box;

          .btn-box {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .btn {
              margin: 8px 0;
              display: inline-block;
              width: 168px;
              height: 36px;
              line-height: 36px;
              text-align: center;
              font-size: 15px;
              color: #fff;
              background: $red-col;
              border-radius: 4px;
              cursor: pointer;
              user-select: none;
              // &:hover {
              //   transform: translateY(-5px);
              // }
            }
          }
        }

        &:hover {
          .mask-box {
            display: block;
          }

          &:nth-child(4n + 4) {
            .mask-box {
              // display: flex;
              left: auto;
              right: 0;
              flex-direction: row-reverse;
              justify-content: center;
            }
          }
        }
      }
    }

    .no-data {
      font-size: 180px;
      padding-top: 80px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;

      .text {
        font-size: 20px;
        margin-top: 10px;
      }
    }
  }

  .infinite-list {
    max-height: 300px;
    overflow-y: auto;
    border-radius: 4px;
    border: 1px solid $red_col();

    .infinite-list-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      padding: 10px;
      cursor: pointer;
      @include font_col6();
      font-size: 14px;
      line-height: 20px;
      border-top: 0.5px dashed $border-col;
      text-align: left;
      height: 50px;
      box-sizing: border-box;

      &:first-child {
        border-top: 0;
      }

      &:hover {
        // color: $white-col !important;
        // background: $red-col !important;
        @include tag_bg_col();
      }
    }
  }
}
</style>

